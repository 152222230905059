@import '../../styles/component.scss';

.page {
  background-color: $color__theme__coconut__300;
}

@include media-breakpoint-up(md) {
  .mobile {
    display: none;
  }
}

.cardWrap {
  display: grid;
  row-gap: 1rem;
  background-color: $color__theme__coconut__300;
  transition: opacity 0.2s;

  @include media-breakpoint-up(xs) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
    background-color: $color__theme__coconut__400;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 16px;
  }
}

.filtersContainer {
  position: sticky;
  z-index: 1;
  top: 49px;
  display: flex;
  flex-direction: column;
}

.filtersWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include media-breakpoint-down(sm) {
    flex-direction: row;
    justify-content: center;

    .filterWrap:not(:first-child) {
      margin-left: 8px;
    }
  }

  @include media-breakpoint-down(xs) {
    position: relative;
  }
}

.filterWrap {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-up(xs) {
    position: relative;
  }

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
    margin: 16px 0;
  }

  @include media-breakpoint-down(sm) {
    height: 30px;
  }
}

.filterHeading {
  white-space: nowrap;
  color: $textFaint;
  @include fontAtlas();
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
  user-select: none;

  &:after {
    content: "";
    display: inline-block;
    margin: 0 0 2px 10px;
    padding: 3.5px;
    border: solid $textFaint;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    transform-origin: center center;
  }

  &:hover {
    color: $color__core__primary;
    text-decoration: none;

    &:after {
      border-color: $color__core__primary;
    }
  }

  &.open {
    &:after {
      margin-bottom: -2px;
      transform: rotate(225deg);
    }

    & + .filterList {
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 6px 12px;
    background-color: $color__opacity--1;
    border: 1px solid $color__opacity--1;
    border-radius: 16px;
    color: $color__core__primary;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.1rem;

    &:after {
      margin-bottom: 3px;
      padding: 2.5px;
      border-color: $color__core__primary;
    }

    &.open {
      border: 1px solid $color__core__primary;

      &:after {
        margin-bottom: 0;
      }
    }
  }
}

.filter {
  color: $black;
  @include sharpGroteskBook();
  font-size: 1rem;
  line-height: 1.6rem;
}

.underline {
  text-decoration: underline;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.filterList {
  list-style: none;
  display: none;
  margin-top: .875rem;
  margin-bottom: 0;
  padding: 0;

  li:not(:last-child) {
    margin-bottom: .75rem;
  }

  a {
    display: none;

    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-down(sm) {
      &.mobile {
        display: flex;
        align-items: center;
        color: $color__core__primary;
        @include fontAtlas();
        font-size: 0.9rem;
        line-height: 1.4rem;

        &::before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          min-width: 20px;
          margin-right: 10px;
          margin-bottom: -3px;
          border: 1px solid $color__core__primary;
        }

        &.active::before {
          padding-top: 7px;
          border-color: transparent;
          background-color: #1e1919;
          background-image: url('/images/assets/check-mark-white.svg');
          background-position: 50% 50%;
          background-size: auto;
          background-repeat: no-repeat;
        }
      }
    }

    &.desktop {
      @extend .filter;

      @include media-breakpoint-up(md) {
        display: block;
      }

      &.active {
        text-decoration: underline;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    position: absolute;
    z-index: 1;
    top: 22px;
    width: 16.25rem;
    background-color: white;
    padding: 12px 12px 0;
    box-shadow: 0 8px 32px rgba(30, 25, 25, 0.03), 0 8px 16px rgba(30, 25, 25, 0.03), 0 4px 8px rgba(30, 25, 25, 0.03), 0 2px 4px rgba(30, 25, 25, 0.03), 0 1px 2px rgba(30, 25, 25, 0.03);

    li {
      margin-bottom: 0.5rem;
      color: $color__core__primary;
      @include fontAtlas();
      font-size: 0.9rem;
      line-height: 1.4rem;
    }
  }

  @include media-breakpoint-down(xs) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.filterLabel {
  color: $textFaint;
  @include fontAtlas();
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.2rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    display: none;
  }

  .clear {
    padding-bottom: 4px;
    border-bottom: 1px solid $colorStone;
    color: $color__core__primary;
    text-decoration: none;
    user-select: none;

    &:hover {
      border-bottom: 0;
    }
  }
}

.helpSection {
  h2 {
    margin-bottom: 4.4rem;
    @include sharpGroteskBook();
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.8rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 5rem;
    padding: 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-evenly;
    }

    li {
      @include sharpGroteskBook();
      font-size: 1.25rem;
      line-height: 1.65rem;
      font-weight: 600;

      @include media-breakpoint-up(sm) {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 400;
      }

      section {
        margin: 0;
      }

      &:nth-child(1) {
        a:before {
          background: transparent url('/images/assets/icon-community.svg') no-repeat center;
        }
      }

      &:nth-child(2) {
        a:before {
          background: transparent url('/images/assets/X-Twitter.png') no-repeat center;
        }
      }

      &:nth-child(3) {
        a:before {
          background: transparent url('/images/assets/icon-contact.svg') no-repeat center;
        }
      }
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    color: $color__core__primary;

    &:before {
      content: '';
      display: inline-block;
      width: 1.5rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
  }
}

/* New styles for search box */
.searchContainer {
  position: relative;
}

.searchBox {
  padding-left: 30px; /* Adjust padding to make space for the icon */
}

.searchContainer svg {
  position: absolute;
  left: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent mouse events on the icon */
}
