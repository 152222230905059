@import '../../styles/component.scss';

.pagination {
  .active span[class='page-link'] {
    background-color: $color__core__primary;
  }

  ul {
    justify-content: center;
    min-width: 200px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  li {
    width: 2rem;
    height: 2rem;
  }

  [class='page-link'] {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 2rem;
    background-color: transparent;
    border: 0;
    color: $color__core__primary;
    @include fontAtlas();
    font-size: .75rem;
    line-height: 1rem;

    &:hover {
      background-color: $color__opacity--1;
    }
  }

  [class='page-item']:nth-child(2) [class='page-link']:first-child span,
  [class='page-item']:nth-last-child(2) [class='page-link']:first-child span {
    font-size: 0;

    &::before {
      content: '';
      display: inline-block;
      padding: 3.5px;
      border: solid $textFaint;
      border-width: 0 1px 1px 0;
      transform-origin: center center;
    }

    &:hover::before {
      border-color: $color__core__primary;
    }
  }

  [class='page-item']:nth-child(2) [class='page-link']:first-child span::before {
    margin: 8px 0 0 2px;
    transform: rotate(-225deg);
  }

  [class='page-item']:nth-last-child(2) [class='page-link']:first-child span::before {
    margin: 8px 4px 0 0;
    transform: rotate(-45deg);
  }

  [class='page-item disabled'] {
    cursor: not-allowed;

    [class='page-link'] {
      background-color: transparent;
    }
  }

  [class='page-item disabled']:nth-child(2) [class='page-link']:first-child span,
  [class='page-item disabled']:nth-last-child(2) [class='page-link']:first-child span {
    font-size: 0;

    &::before {
      content: '';
      display: inline-block;
      padding: 3.5px;
      border: solid $colorPandaGrayDark;
      border-width: 0 1px 1px 0;
      transform-origin: center center;
    }

    &:hover::before {
      border-color: $color__core__primary;
    }
  }

  [class='page-item disabled']:nth-child(2) [class='page-link']:first-child span::before {
    margin: 8px 0 0 2px;
    transform: rotate(-225deg);
  }

  [class='page-item disabled']:nth-last-child(2) [class='page-link']:first-child span::before {
    margin: 8px 4px 0 0;
    transform: rotate(-45deg);
  }

  [class='page-item']:first-child [class='page-link']:first-child span,
  [class='page-item']:last-child [class='page-link']:first-child span {

    font-size: 2rem;
    margin-bottom: 8px;
  }

  [class='page-item disabled'] {
    cursor: not-allowed;

    [class='page-link'] {
      background-color: transparent;
    }
  }

  [class='page-item disabled']:first-child [class='page-link']:first-child span,
  [class='page-item disabled']:last-child [class='page-link']:first-child span {

    font-size: 2rem;
    /* line-height: 0rem; */
    margin-bottom: 8px;
  }

}

.loadMore {
  color: $color__core__primary;
  @include fontAtlas();
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;

  @include media-breakpoint-up(xs) {
    display: none;
  }

  a {
    text-decoration: none;
  }
}
