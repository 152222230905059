/* Default config */

/* Variables */

// Colors
$black: #000000;
$white: #ffffff;
$wine: #61082b; // dark cherry (DBX)
$mustard: #ffe7ab;
$mallard: #005744;
$periwinkle: #b4cfe9;
$cardBorderColor: #d0d0d3;
$navLinkGray: #3372e6;

$colorStone: #D0D0D3;
$colorCornflower: #a0b7ff;
$colorLavender: #ceb4ff;
$colorLightBlue: #99c0ff;
$colorShark:  #637381;
$colorPandaGrayExtraLight: #fafafa;
$colorPandaGrayLight: #eeeeee;
$colorPandaGray: #ebebeb;
$colorPandaGrayMedium: #e0e0e0;
$colorPandaGrayDark: #b8b8b8;
$colorSand: #d8bea2;
$colorSapphire: #0d2481;
$colorCloud: #b4d0e7;
$colorCrimson: #9b0033;
$colorOrchid: #ceb4ff;
$colorBlackCherry: #61082b;
$colorFrenchVanilla: #ffe7aa;
$colorCanopy: #005744;
$colorPeriwinkle: #b4cfe9;

// From DSS
$color__core__primary: #1E1919;
$color__theme__coconut__300: #faf9f7;
$color__theme__coconut__400: #f7f5f2;
$color__opacity--1: rgba(166, 145, 113, 0.14);
$color__opacity--2: rgba(166, 145, 113, 0.24);

// Custom
$black-opacity-15: rgba(0, 0, 0, 0.15);
$textFaint: rgba(82, 74, 62, 0.75);

// Fonts
$font-headline: 'Sharp Grotesk DB Medium';

$colors: () !default;
$colors: map-merge(
  (
    'white': $white,
    'black': $black,
    'lightblue': $colorLightBlue,
    'lavender': $colorLavender,
    'pandaGray': $colorPandaGray,
    'pandaGrayExtraLight': $colorPandaGrayExtraLight,
    'pandaGrayLight': $colorPandaGrayLight,
    'pandaGrayMedium': $colorPandaGrayMedium,
    'pandaGrayDark': $colorPandaGrayDark,
    'stone': $colorStone,
    'shark': $colorShark,
    'sapphire': $colorSapphire,
    'sand': $colorSand,
    'cloud': $colorCloud,
    'orchid': $colorOrchid,
    'blackCherry': $colorBlackCherry,
    'frenchVanilla': $colorFrenchVanilla,
    'canopy': $colorCanopy,
    'periwinkle': $colorPeriwinkle,
    'crimson': $colorCrimson
  ),
  $colors
);

$theme-colors: ();
$theme-colors: map-merge(
  (
    'white': $white,
    'lavender': $colorLavender,
    'cornflower': $colorCornflower,
    'wine': $wine,
    'mustard': $mustard,
    'mallard': $mallard,
    'periwinkle': $colorPeriwinkle,
    'blackCherry': $colorBlackCherry,
    'default': $black,
    'solid': $black
  ),
  $theme-colors
);

@import './theme';

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

/** Import Bootstrap functions */
@import '~bootstrap/scss/functions';

$body-color: #000;
$border-width: 1px !default;
$border-color: theme-color('black'); // $gray-300 !default;

$input-color: $body-color;
$input-border-color: $colorStone;
$input-border-width: $border-width;

$input-btn-padding-y: 0.5625rem ;// 0.375rem !default;
$input-btn-padding-x: 1.5625rem; // 0.75rem !default;
$input-btn-focus-width: 0.2rem !default;
$input-focus-border-color: $input-border-color; // lighten($body-color, 25%) !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-focus-border-color !default;
$input-placeholder-color: $colorShark; // lighten($body-color, 50%) !default; // $input-color;

$label-margin-bottom: 0.5rem !default;
$border-radius: 0; // .25rem !default;
$border-radius-lg: 0; // .3rem !default;
$border-radius-sm: 0; // .2rem !default;
$grid-columns: 24; // This overrides default value (12)
$grid-gutter-width: 1rem;

$grid-gutter-widths: (
  default: $grid-gutter-width,
  xs: $grid-gutter-width,
  sm: $grid-gutter-width,
  md: $grid-gutter-width * 1.5,
  lg: $grid-gutter-width * 2,
) !default;

$section-margins: (
  default: (
    top: 3.125rem,
    bottom: 3.125rem
  ),
  xs: (
    top: 4.625rem,
    bottom: 4.625rem
  ),
  sm: (
    top: 4.625rem,
    bottom: 4.625rem
  ),
  md: (
    top: 4.625rem,
    bottom: 4.625rem
  ),
  lg: (
    top: 6.25rem,
    bottom: 6.25rem
  )
) !default;

$sub-section-margins: (
  default: (
    top: calc(3.125rem / 2),
    bottom: calc(3.125rem / 2)
  ),
  xs: (
    top: calc(4.625rem / 2),
    bottom: calc(4.625rem / 2)
  ),
  sm: (
    top: calc(4.625rem / 2),
    bottom: calc(4.625rem / 2)
  ),
  md: (
    top: calc(4.625rem / 2),
    bottom: calc(4.625rem / 2)
  ),
  lg: (
    top: calc(6.25rem / 2),
    bottom: calc(6.25rem / 2)
  )
) !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: $input-focus-box-shadow; // inset 0 3px 5px rgba($black, .125) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xxs: 0,
  xs: 481px,
  sm: 800px,
  md: 1024px,
  lg: 1440px
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xs: 700px,
  sm: 964px,
  md: 1111px,
  lg: 1112px
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');

$body-bg: #fff;

// Cards

$card-spacer-y: 1.875rem; // 1.75rem !default;
$card-spacer-x: 1.875rem; // 1.25rem !default;
$card-border-width: 1px; // $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: $cardBorderColor; // rgba($black, .125) !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-bg: $white; // rgba($black, .03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white; // $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: calc($grid-gutter-width / 2) !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: 'Atlas Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$font-size-xs: ($font-size-base * 0.75) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.625 !default;

$h1-font-size: $font-size-base * 4.0625; // $font-size-base * 2.5 !default;
$h1-line-height: 1.15;

$h2-font-size: $font-size-base * 2.5; // $font-size-base * 2 !default;
$h2-line-height: 1.15;

$h3-font-size: $font-size-base * 2; // $font-size-base * 1.75 !default;
$h3-line-height: 1.1875;

$h4-font-size: $font-size-base * 1.5625; // $font-size-base * 1.5 !default;
$h4-line-height: 1.28;

$h5-font-size: $font-size-base * 1; // $font-size-base * 1.25 !default;
$h5-line-height: $font-size-base * 1.625;

$h6-font-size: $font-size-base !default;
$h6-line-height: $font-size-base * 1.5;

$headings-margin-bottom: $spacer * 2; // ($spacer / 2) !default;
$headings-font-family: 'Sharp Grotesk DB Medium 22', $font-family-sans-serif; // inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.5 !default;
$headings-color: inherit !default;

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: rgba($colorShark, 0.3) !default;

// Navbar

$navbar-padding-y: calc($spacer / 2) !default;
$navbar-padding-x: $spacer !default;

$navbar-nav-link-padding-x: 0.75rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $line-height-base + $nav-link-padding-y * 2; // $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: calc(($nav-link-height - $navbar-brand-height) / 4) !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0; // 0.75rem !default;
$navbar-toggler-font-size: $font-size-sm !default;

$navbar-light-color: $colorShark; // rgba($black, .5) !default;
$navbar-light-hover-color: $colorShark; // rgba($black, .7) !default;
$navbar-light-active-color: $colorShark; // rgba($black, .9) !default;
$navbar-light-disabled-color: rgba($colorShark, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$black}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: transparent;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;

// Breadcrumbs

$breadcrumb-font-size: 0.8125rem; // null !default;

$breadcrumb-padding-y: 1.5rem; // .75rem !default;
$breadcrumb-padding-x: 0; // 1rem !default;
$breadcrumb-item-padding: 0.5rem !default;

$breadcrumb-margin-bottom: 0; // 1rem !default;

$breadcrumb-bg: transparent; // $gray-200 !default;
$breadcrumb-divider-color: $colorShark; // $gray-600 !default;
$breadcrumb-active-color: $colorShark; // $gray-600 !default;
$breadcrumb-divider: url('/images/assets/icon-chevron-right.svg');

$breadcrumb-border-radius: $border-radius !default;

// Navs
// Tabs component

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;

$nav-tabs-border-color: transparent; // $gray-300 !default;
$nav-tabs-border-width: 2px !default;
$nav-tabs-border-radius: 0; // $border-radius !default;
$nav-tabs-link-hover-border-color: transparent; // $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $body-color; // $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: transparent transparent #3f3f3f transparent !default;

$modal-dialog-margin:  1rem;

// Progress bars

$progress-height:                   0.5rem; // 1rem !default;
$progress-font-size:                $font-size-sm; // $font-size-base * .75 !default;
$progress-bg:                       $black; // $gray-200 !default;
$progress-border-radius:            0; // $border-radius !default;
$progress-box-shadow:               none; // inset 0 .1rem .1rem rgba($black, .1) !default;
$progress-bar-color:                $black; // $white !default;
$progress-bar-bg:                   $black; // theme-color("primary") !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;
