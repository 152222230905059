/* Mixins and functions */

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins/_breakpoints';

// Convert px to rem
@function rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// Input placeholders
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

/* ====================
  Base / Mixins
  ===================== */
  @mixin center-both {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @mixin center-vertical {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @mixin center-horizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // Break out of template frame (.x-wrap margins and max-widths)
  @mixin break-wrap {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  // CSS image replacement
  @mixin hide-text() {
    background-color: transparent;
    border: 0;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
  }

  @mixin fontAtlas() {
    font-family: "Atlas Grotesk", sans-serif !important;
  }

  @mixin fontAtlasMedium() {
    font-family: "Atlas Grotesk Medium", sans-serif !important;
    font-weight: normal !important;
  }

  @mixin sharpGroteskBook() {
    font-family: "Sharp Grotesk DB Book", sans-serif !important;
  }

  @mixin sharpGroteskMedium() {
    font-family: "Sharp Grotesk DB Medium", sans-serif !important;
  }

  @mixin sharpGroteskMedium22() {
    font-family: "Sharp Grotesk DB Medium 22", sans-serif !important;
  }

  @mixin fontBase() {
    font-family: $font-family-base, sans-serif !important;
    line-height: $line-height-base;
    font-weight: $body-color;
  }

  @mixin fontLinkColorReset() {
    color: $body-color;

    &:hover,
    &:visited {
      text-decoration: none;
      color: inherit;
    }
  }

  @mixin fontBaseReset() {
    font-family: $font-family-base, sans-serif !important;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-base;

    @include fontLinkColorReset();
  }

  @mixin buttonReset() {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
